@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.noScollbar::-webkit-scrollbar {
  display: none;
  width: 0;
}

/* Hide scrollbar for IE, Edge and Firefox */
.noScollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.paginate ul {
  display: flex;
  margin-top: 50px;
  gap: 13px;
}

.pageClass {
  font-size: 20px;
  padding: 7px 16px;
  border-radius: 5px;
  color: #0094D6;
  border: 2px solid #0094D6;
  cursor: pointer;
}

.paginate ul li {
  display: flex;
  align-items: center;
}

.prevClass {
  font-size: 20px;
  padding: 10px 11px;
  border-radius: 5px;
  color: #0094D6;
  border: 2px solid #0094D6;
  cursor: pointer;
}

.nextClass {
  font-size: 20px;
  padding: 10px 11px;
  border-radius: 5px;
  color: #0094D6;
  border: 2px solid #0094D6;
  cursor: pointer;
}

.pageClass:hover {
  background: #0094D6;
  color: white;
  transition: all ease .3s;
}

.prevClass:hover {
  background: #0094D6;
  color: white;
  transition: all ease .3s;
}

.nextClass:hover {
  background: #0094D6;
  color: white;
  transition: all ease .3s;
}

.activeClass {
  cursor: pointer;
  background: #0094D6;
  color: white;
}

.react-multi-carousel-dot--active button {
  background-color: #0094D6 !important;
  width: 30px !important;
  border-radius: 7px !important;
  transition: all ease .3s;
}

.react-multi-carousel-dot button {
  background-color: #0094D6 !important;
  transition: width .3s !important;
  border: 0 !important;
}

.similar .react-multiple-carousel__arrow {
  position: absolute !important;
}

.similar .react-multiple-carousel__arrow--left {
  top: -70px !important;
  left: 88% !important;
  background: transparent !important;
  border: 2px solid #0094D6;
}

.similar .react-multiple-carousel__arrow--left::before {
  color: #0094D6 !important;
}

.similar .react-multiple-carousel__arrow--right {
  top: -70px !important;
  right: 3% !important;

  background: transparent !important;
  border: 2px solid #0094D6;
}

.similar .react-multiple-carousel__arrow--right::before {
  color: #0094D6 !important;
}

.similar .react-multi-carousel-list {
  overflow: unset !important;
}

.active {
  background-image: linear-gradient(0deg, #04B0EE, #0094D6) !important;
  color: white !important;
}

.active .icon {
  color: white !important;
}

.line-border {
  position: relative;
}

.line-border::before {
  content: '';
  border: 1px dashed #555B6A;
  height: 20px;
  position: absolute;
  top: -50%;
  margin-left: 1px;
}

.modal-sticky {
  width: 100%;
  display: flex !important;
  position: fixed !important;
  height: 100%;
  top: 0 !important;
  right: 0 !important;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
}

.modal-sticky .modal-dialog {
  border-radius: 16px !important;
  max-width: 1000px;
  max-height: 90vh;
  overflow: hidden;
}

.modal-sticky .modal-content {
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-sticky .modal-content .close {
  position: absolute;
  top: 7px;
  right: 10px;
  cursor: pointer;
  z-index: 999;
}

.modal-sticky .modal-content .modalbody {
  overflow-x: hidden;
}

.modal-sticky .modal-content .modalbody .modal-main {
  padding: 20px 30px !important;
}

.react-select .select__control {
  border: 1px solid #6B6B6B;
  padding: 5px 10px;
  border-radius: 7px;
}

.docModal .react-select .select__control {
  border: none;
}

.react-select .select__control .css-tj5bde-Svg {
  color: black !important;
}

.docModal .react-select .select__control .select__placeholder {
  font-size: 14px;
  font-family: 'Cairo', sans-serif;
  color: black;
  font-weight: 600;
}

.PhoneInput input {
  outline: none;
}

.subscribe .react-select .select__control {
  border: 1px solid #C7C7C7;
  padding: 5px 10px;
}

.slider .cursor-pointer {
  cursor: default;
}

.react-datepicker-wrapper {
  width: 100%;
}

.scholarship .react-select .select__control {
  border-radius: 28px !important;
  border: 1px solid #0094D6 !important;
  color: #0092d648 !important;
}

.scholarship .css-1jqq78o-placeholder {
  color: #0094D6 !important;
}

.scholarship .css-tj5bde-Svg {
  color: #0094D6 !important;
}

.scholarship .css-1dimb5e-singleValue {
  color: #0094D6 !important;
}

.talkTo .react-select .select__control {
  border-radius: 23px;
}

.step .react-select .select__control {
  border-radius: 40px;
  border: 0 !important;
}


.latest .react-multiple-carousel__arrow {
  position: absolute;
  top: 92% !important;
  left: 10px;
  background: none;
  min-width: 28px;
}

.latest .react-multiple-carousel__arrow:hover {
  background: none;
}

.latest .react-multiple-carousel__arrow--right {
  right: 10px !important;
  left: 97%;
}

.flatpicker.active {
  background: #fff !important;
  color: #000 !important;
}

input[type=radio] {
  accent-color: #0094D6;
}

input[type=checkbox] {
  accent-color: #0094D6;
}

input[type=text] {
  background-color: white;
}

input[type=number] {
  background-color: white ;
}

.react-select input[type=text] {
  background-color: transparent !important;
}


@media (max-width: 800px) {
  .latest .react-multiple-carousel__arrow--right {
    right: 10px !important;
    left: 92%;
  }
}

/* for css debugging purpose */
.b5 {
  outline: 5px solid red !important
}

/* for css debugging purpose */

.upload-document .select__control{
  border: 1px solid #E7E7E7 !important;
}

.select__control--menu-is-open {
  z-index:999 !important;
}